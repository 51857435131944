/* eslint-disable import/no-unused-modules */
import React from 'react';
import { graphql } from 'gatsby';

import FeatureList from '../components/FeatureList/FeatureList';
import HomeImageList from '../components/List/HomeImageList';

import TrialStart from '../modules/home/TrialStart';
import { PARTNERS_AND_CERTIFICATIONS_LIST } from '../config';
import FadeInAnimation from '../shared/styles/FadeInAnimation';

const FeaturesPage = () => {
  return (
    <div data-aos={FadeInAnimation()}>
      <FeatureList page={true} />
      <TrialStart />
      <HomeImageList
        title="Partnerships and Certificates"
        ImageList={PARTNERS_AND_CERTIFICATIONS_LIST}
      />
    </div>
  );
};
export default FeaturesPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
